import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import axios from "axios";

const CSRCertificate = (props: any) => {
  const [formData, setFormData] = useState<any>("");
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<Boolean>(false);

  const navigate = useNavigate();
  document.title = "ZATCA E-Invoicing By Nebras";
  // Inside your component

  const printInvoice = () => {
    window.print();
  };

  const handleFormData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("form data", formData);
  };

  const handleGenerateCSR = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    console.log("sending this one ", formData);
    try {
      const response = await axios.post(
        "https://api-zatca-uat.nebrascorp.com/api/csr/generate",
        formData
      );
      setLoading(false);

      console.log("response.data");
      console.log(response.data);
      setData(response.data?.data);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 403) {
        // console.log(error?.response);
        // Swal.fire({
        //   title: "Already Exist!",
        //   text: `${error?.response?.data?.message}`,
        //   icon: "warning",
        // });
      }
      console.log(error);
    }
  };

  // useEffect(() => {
  // }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="CSR"
            breadcrumbItem="Generate Certificate Signing Request"
          />
          {true && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                          <div className="mb-2">
                            {/* <img src={logo} alt="" height="24" /> */}
                            <span className="logo-txt">
                              Enter Company Details
                            </span>
                            <br />
                            <span
                              style={{
                                marginLeft: "5px",
                                marginTop: "0px",
                                marginBottom: "0px",
                              }}
                            >
                              Enter Company Details (All the fields are
                              required) &nbsp;
                            </span>
                            <br />
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="mb-4">
                            <Link to="/clearance">
                              <FeatherIcon
                                icon="x-circle"
                                className="text-muted hover-red"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />

                    <Form onSubmit={handleGenerateCSR}>
                      <Row>
                        {/* col1 */}
                        <Col sm="3">
                          <div className="border-bottom   p-2">
                            <span>Common Name</span>
                            <Input
                              type="text"
                              name="csr.common.name"
                              onChange={handleFormData}
                              required
                            ></Input>
                            <br />
                          </div>

                          <div className="border-bottom   p-2">
                            <span>Serial Number</span>
                            <Input
                              type="text"
                              name="csr.serial.number"
                              onChange={handleFormData}
                              required
                            ></Input>
                            <br />
                          </div>
                        </Col>

                        {/* col2 */}
                        <Col sm="3">
                          <div className="border-bottom  p-2">
                            <span>Organization Identifier</span>
                            <Input
                              type="text"
                              required
                              name="csr.organization.identifier"
                              onChange={handleFormData}
                            ></Input>
                            <br />
                          </div>

                          <div className="border-bottom p-2">
                            <span>Organizaion Unit Name</span>
                            <Input
                              required
                              type="text"
                              name="csr.organization.unit.name"
                              onChange={handleFormData}
                            ></Input>
                            <br />
                          </div>
                        </Col>

                        {/* col3 */}
                        <Col sm="3">
                          <div className="border-bottom  p-2">
                            <span>Organization Name</span>
                            <Input
                              type="text"
                              required
                              name="csr.organization.name"
                              onChange={handleFormData}
                            ></Input>
                            <br />
                          </div>

                          <div className="border-bottom p-2">
                            <span>Country Name</span>
                            <Input
                              type="text"
                              required
                              name="csr.country.name"
                              onChange={(e: any) => {
                                handleFormData(e);
                              }}
                            ></Input>
                            <br />
                          </div>
                        </Col>

                        {/* col4 */}
                        <Col sm="3">
                          <div className="border-bottom p-2">
                            <span>Invoice Type</span>
                            <Input
                              type="text"
                              required
                              name="csr.invoice.type"
                              onChange={(e: any) => {
                                handleFormData(e);
                              }}
                            ></Input>
                            <br />
                          </div>

                          <div className="border-bottom p-2">
                            <span>Location Address</span>
                            <Input
                              type="text"
                              required
                              name="csr.location.address"
                              onChange={(e: any) => {
                                handleFormData(e);
                              }}
                            ></Input>
                            <br />
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="border-bottom p-2">
                            <span>Industry Business Category</span>
                            <Input
                              type="text"
                              required
                              name="csr.industry.business.category"
                              onChange={(e: any) => {
                                handleFormData(e);
                              }}
                            ></Input>

                            <br />
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      {!loading ? (
                        <>
                          <Row>
                            <Col sm="6">
                              <div>
                                <Label
                                  htmlFor="outcome"
                                  style={{ width: "100%" }}
                                >
                                  <span className="font-size-17">
                                    Generated CSR
                                  </span>
                                </Label>{" "}
                                <div
                                  className="form-control"
                                  style={{
                                    width: "90%",
                                    marginTop: "12px",
                                    minHeight: "200px",
                                  }}
                                  placeholder="Enter updated outcome..."
                                  id="outcome"
                                >
                                  <span className="text-muted font-size-10">
                                    {data?.binaryToken}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col sm="6">
                              <div>
                                <Label
                                  htmlFor="outcome"
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  <span className="font-size-17">
                                    Generated Key
                                  </span>
                                </Label>

                                <br />
                                <div
                                  placeholder="Enter updated remarks..."
                                  style={{
                                    width: "90%",
                                    marginTop: "12px",
                                    minHeight: "200px",
                                  }}
                                  className="form-control min-height-200"
                                  id="outcome"
                                >
                                  <span className="text-muted font-size-10">
                                    {data?.secretKey}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col style={{ minHeight: "200px" }} sm="12">
                              <div className=" w-100   align-center text-center">
                                <Spinner
                                  color="primary"
                                  style={{
                                    margin: "auto",
                                    height: "3rem",
                                    width: "3rem",
                                  }}
                                  type="grow"
                                ></Spinner>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      <div className="d-print-none mt-4">
                        <div className="float-end">
                          <Button type="submit" className="btn btn-success">
                            Generate Now
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CSRCertificate;
