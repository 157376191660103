import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";

// Ag
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  ColGroupDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDetailCellRendererParams,
  ModuleRegistry,
  createGrid,
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { IAccount, ICallRecord } from "./interfaces";
import Swal from "sweetalert2";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
]);

const CompanyProfile = (props: any) => {
  document.title = "ZATCA E-Invoicing By Nebras";
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>(false);
  const [formData, setFormData] = useState<any>();
  const [branchData, setFormBranchData] = useState<any>();

  const handleFormData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("form data", formData);
  };
  const handleBranchData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;
    setFormBranchData({
      ...branchData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("branch data", branchData);
  };

  // Inside your component

  const toggleLeftCanvas = () => {
    setOpen(!open);
  };
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<any[]>([]); // Change IAccount to any[]
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    { field: "S No.", cellRenderer: "agGroupCellRenderer" },
    { field: "cocode" }, // Add organization fields
    { field: "commonName" },
    { field: "clientNo" },
    { field: "name" },
    { field: "organizationIdentifier" },
    { field: "countryName" },
    { field: "location", hide: true }, // Assuming you want to display the location field
    { field: "invoiceType" },
    { field: "companyURL" },
    { field: "region" },
    { field: "industry" },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
    };
  }, []);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          { field: "uuid" }, // Adjust detail fields according to your model
          { field: "retailstoreno" },
          { field: "retailstorestxt" },
          { field: "serialNumber" },
          { field: "countryName" },
          { field: "region" },
          { field: "street" },
          // Add more detail fields as needed
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.location); // Assuming location is an array in your model
      },
    };
  }, []);

  // Sample organization data
  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(
        "https://api-zatca-uat.nebrascorp.com/api/organizations"
      );

      console.log("organization response", response?.data);
      setRowData(response?.data?.response);
    } catch (error) {
      console.log("Faild to fetch organizations", error);
    }
  };
  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleCreateOrganization = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api-zatca-uat.nebrascorp.com/api/add-organization",
        formData
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Created",
          text: `Organization Created `,
          icon: "success",
        });
      }
      console.log("response is here", response.data);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        Swal.fire({
          title: "Error!",
          text: `${error?.response?.data?.error}`,
          icon: "warning",
        });
      }
      console.log(error);
    }
  };
  const handleAddBranch = (e: any) => {
    e.preventDefault();

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      location: prevFormData?.location
        ? [...prevFormData?.location, branchData]
        : [branchData],
    }));

    setFormBranchData(null);
    setFormBranchData({
      uuid: "",
      retailstoreno: "",
      retailstorestxt: "",
      serialNumber: "",
      organizationIdentifier: "",
      countryName: "",
      region: "",
      city: "",
      postal: "",
      street: "",
    });
    console.log(formData);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="CSR" breadcrumbItem="Companies List" /> */}

          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <span className="mb-0 font-size-18">
              All Registered Organizaiton ({rowData?.length})
            </span>
            <div className="float-end">
              <Button onClick={toggleLeftCanvas} className="btn btn-success">
                Add Organization
              </Button>
            </div>
          </div>
          <div style={containerStyle}>
            {rowData && (
              <div style={gridStyle} className={"ag-theme-quartz"}>
                <AgGridReact<IAccount>
                  rowData={rowData}
                  masterDetail={true}
                  // detailCellRenderer={detailCellRenderer}
                  detailCellRendererParams={detailCellRendererParams}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // onGridReady={onGridReady}
                  // onFirstDataRendered={onFirstDataRendered}
                />
              </div>
            )}{" "}
          </div>
        </Container>
        <Offcanvas
          isOpen={open}
          toggle={toggleLeftCanvas}
          direction="end"
          style={{ minWidth: "90%" }}
        >
          <OffcanvasHeader toggle={toggleLeftCanvas}>
            ADD New Organization
          </OffcanvasHeader>
          <OffcanvasBody
            style={{
              // display: "flex",
              // justifyContent: "space-between",
              // flexDirection: "row",
              width: "100%",
            }}
          >
            <Row
              style={{
                width: "95%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Form
                onSubmit={handleCreateOrganization}
                style={{ width: "40%" }}
              >
                <h5>Organization Details</h5>
                <FormGroup floating>
                  <Input
                    type="tel"
                    id="orgident"
                    required
                    name="organizationIdentifier"
                    onChange={handleFormData}
                    maxLength={15}
                  ></Input>
                  <Label for="orgident">Organization Identifier</Label>
                </FormGroup>{" "}
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="number"
                        name="commonName"
                        onChange={handleFormData}
                        id="commonName"
                        maxLength={10}
                        placeholder="Max 10 digit "
                        required
                      ></Input>
                      <Label for="commonName">Common Name</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="number"
                        name="name"
                        onChange={handleFormData}
                        id="name"
                        required
                      ></Input>
                      <Label for="">Name</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="number"
                        name="cocode"
                        onChange={handleFormData}
                        id="cocode"
                        required
                      ></Input>
                      <Label for="">Cocode</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="clientNo"
                        onChange={handleFormData}
                        id="clientNo"
                        required
                      ></Input>
                      <Label for="">Client No</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="invoiceType"
                        maxLength={4}
                        onChange={handleFormData}
                        id="invoiceType"
                        required
                      ></Input>
                      <Label for="">Invoice Type</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="industry"
                        onChange={handleFormData}
                        id="industry"
                        required
                      ></Input>
                      <Label for="">Industry</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="address"
                        // maxLength={2}
                        onChange={handleFormData}
                        id="address"
                        required
                      ></Input>
                      <Label for="">Address</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="companyURL"
                        onChange={handleFormData}
                        id="companyURL"
                        required
                      ></Input>
                      <Label for="">Company URL</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="countryName"
                        maxLength={2}
                        onChange={handleFormData}
                        id="countryName"
                        required
                      ></Input>
                      <Label for="">Country Name</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="region"
                        onChange={handleFormData}
                        id="region"
                        required
                      ></Input>
                      <Label for="">Region</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Button type="submit" className="btn btn-success">
                  Create Organization
                </Button>
              </Form>

              <Form onSubmit={handleAddBranch} style={{ width: "40%" }}>
                <h5> Locations (Branches)</h5>
                <FormGroup floating>
                  <Input
                    type="text"
                    id="organizationIdentifier"
                    required
                    name="organizationIdentifier"
                    value={branchData?.organizationIdentifier}
                    onChange={handleBranchData}
                    maxLength={15}
                  ></Input>
                  <Label for="organizationIdentifier">
                    Organization Identifier
                  </Label>
                </FormGroup>{" "}
                <FormGroup floating>
                  <Input
                    type="text"
                    id="uuid"
                    required
                    name="uuid"
                    value={branchData?.uuid}
                    onChange={handleBranchData}
                  ></Input>
                  <Label for="uuid">UUID</Label>
                </FormGroup>{" "}
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="number"
                        name="retailstoreno"
                        value={branchData?.retailstoreno}
                        onChange={handleBranchData}
                        id="retailstoreno"
                        required
                      ></Input>
                      <Label for="retailstoreno">Retailstore No</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="retailstorestxt"
                        value={branchData?.retailstorestxt}
                        onChange={handleBranchData}
                        id="retailstorestxt"
                        required
                      ></Input>
                      <Label for="">Retailstores Txt</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                {/*  */}
                <FormGroup floating>
                  <Input
                    type="text"
                    id="serialNumber"
                    required
                    name="serialNumber"
                    value={branchData?.serialNumber}
                    onChange={handleBranchData}
                    maxLength={15}
                  ></Input>
                  <Label for="serialNumber">Serial Number</Label>
                </FormGroup>{" "}
                {/*  */}
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="countryName"
                        value={branchData?.countryName}
                        onChange={handleBranchData}
                        id="countryName"
                        maxLength={2}
                        required
                      ></Input>
                      <Label for="">Country Name</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="region"
                        value={branchData?.clientNo}
                        onChange={handleBranchData}
                        id="region"
                        required
                      ></Input>
                      <Label for="">Region</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="city"
                        value={branchData?.city}
                        maxLength={4}
                        onChange={handleBranchData}
                        id="city"
                        required
                      ></Input>
                      <Label for="">City</Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="number"
                        name="postal"
                        value={branchData?.postal}
                        onChange={handleBranchData}
                        id="postal"
                        required
                      ></Input>
                      <Label for="">Postal</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="street"
                        value={branchData?.street}
                        onChange={handleBranchData}
                        id="street"
                        required
                      ></Input>
                      <Label for="">street </Label>
                    </FormGroup>{" "}
                  </Col>
                  <Col xs={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="companyURL"
                        value={branchData?.companyURL}
                        onChange={handleBranchData}
                        id="companyURL"
                        required
                      ></Input>
                      <Label for="">Company URL</Label>
                    </FormGroup>{" "}
                  </Col>
                </Row>
                <Button className="btn btn-success">Add Branch</Button>
              </Form>
            </Row>
            <hr />
            <br />
            <Row>
              {formData?.location?.map((val: any) => (
                <div
                  style={{ maxWidth: "300px" }}
                  className="box radius border card"
                >
                  {val?.retailstoreno}
                </div>
              ))}
            </Row>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default CompanyProfile;
