import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";

// Ag
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef,
  FirstDataRenderedEvent,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import DetailCellRenderer from "./detailCellRenderer";
import { IAccount } from "./interfaces";
import Swal from "sweetalert2";
// import { compliance } from "src/dummyData";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  MenuModule,
]);

const CSIDOnboardingGenerator = (props: any) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>("");
  const [complianceCSID, setComplianceCSID] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);

  document.title = "ZATCA E-Invoicing By Nebras";
  // Inside your component
  const DetailCellRenderer = (props: any) => {
    const data = props.data;

    return (
      <Row className=" detail-row">
        <Col xs={6} className="detail-col">
          <div className="detail-group">
            <div className="detail-label">ID:</div>
            <div>{data._id}</div>
          </div>
          <div className="detail-group ">
            <div className="detail-label">Binary Token:</div>
            <div className="detail-value">{data?.binaryToken}</div>
          </div>

          <div className="detail-group">
            <div className="detail-label">Secret Key:</div>
            <div>{data.secretKey}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Serial Number:</div>
            <div>{data.serialNumber}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Session:</div>
            <div>{data.session}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Status:</div>
            <div>{data.status}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Version:</div>
            <div>{data.version}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">Timestamp:</div>
            <div>{data.dbtimestamp}</div>
          </div>
        </Col>
        <Col xs={4} className="text-right">
          <div className="detail-group">
            <div className="detail-label">CSR Config Name:</div>
            <div>{data.binaryToken}</div>
          </div>
          {/* <div className="detail-group">
            <div className="detail-label">CSR Config Path:</div>
            <div>{data.directory.CSRConfig.path}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">CSR Name:</div>
            <div>{data.directory.CSR.name}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">CSR Path:</div>
            <div>{data.directory.CSR.path}</div>
          </div>
          <div className="detail-group">
            <div className="detail-label">PAK Name:</div>
            <div>{data.directory.PAK.name}</div>
          </div> */}
        </Col>
        <Col xs={2}>
          <Link to="#" className="btn btn-success" onClick={toggleLeftCanvas}>
            Generate CSID{" "}
          </Link>
        </Col>
      </Row>
    );
  };

  const containerStyle = useMemo(
    () => ({ width: "100%", height: "500px" }),
    []
  );

  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  const detailCellRenderer = useCallback(DetailCellRenderer, []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [columnDefs, setColumnDefs] = useState<any>([
    { field: "S No.", cellRenderer: "agGroupCellRenderer" },
    { field: "_id", hide: false },
    { field: "certificateSigningRequest", hide: true },
    { field: "requestId" },
    { field: "binaryToken", hide: true },
    { field: "securityToken", hide: true },
    { field: "secretKey", hide: true },
    { field: "session" }, // Assuming session is a field in your data model
    { field: "status" }, // Assuming status is a field in your data model
    { field: "version" },
    { field: "dbtimestamp", cellRenderer: "dateTimeRenderer" },
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
    };
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.api.forEachNode(function (node) {
      node.setExpanded(node.id === "1");
    });
  }, []);

  const fetchCSIDCompliance = async () => {
    try {
      const response = await axios.get(
        "https://api-zatca-uat.nebrascorp.com/api/csidcompliance"
      );
      console.log("csid compliance response", response.data?.response);
      setComplianceCSID(response.data?.response);
      console.log("api response CSID compliance", response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCSIDCompliance();
  }, []);

  const handleFormData = async (e: any) => {
    e.stopPropagation();
    let { name, value, type, files } = e.target;

    if (name === "csid") {
      // console.log("abcs", JSON.parse(value));
      setFormData({
        ...formData,
        id: JSON.parse(value)._id,
        requestId: JSON.parse(value).requestId,
        username: JSON.parse(value).binaryToken,
        password: JSON.parse(value).secretKey,
        accept_version: JSON.parse(value).version,
      });
      console.log("done eh");
      return;
    }

    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
    console.log("form data", formData);
  };

  const handleGenerateCSID = async (e: any) => {
    e.preventDefault();
    console.log("formData", formData);
    const headers = {
      "accept-version": formData.accept_version,
    };

    console.log("sending this one on 'body'  ", formData);
    console.log("sending this header'  ", headers);
    try {
      console.log("V2", formData);
      const response = await axios.post(
        "https://api-zatca-uat.nebrascorp.com/api/onboarding/csid",
        {
          compliance_request_id: formData.requestId,
          cryptographicStampIdentifier: formData.id,
        },

        {
          headers,
          auth: {
            username: formData.username,
            password: formData.password,
          },
        }
      );

      console.log("response.data");
      console.log(response.data?.data);
      if (response.status === 200) {
        Swal.fire({
          title: "Onboarding Success!",
          text: `Onboarding record is generated and saved in DB `,
          icon: "success",
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 403) {
        // console.log(error?.response);
        // Swal.fire({
        //   title: "Already Exist!",
        //   text: `${error?.response?.data?.message}`,
        //   icon: "warning",
        // });
      }
      console.log(error);
    }
  };

  // _id: 'ObjectId("
  // csr: 'ObjectId("
  // requestId: "1234567890123",
  // binaryToken:
  // "TUlJQ1REQ0NBZk9nQXdJQkFnSUdBWTVxbHlHa01Bb0dDQ3FHU000OUJBTUNNQlV4RXpBUkJnTlZCQU1NQ21WSmJuWnZhV05wYm1jd0hoY05NalF3TXpJek1Ea3hOREk0V2hjTk1qa3dNekl5TWpFd01EQXdXakNCa1RFTE1Ba0dBMVVFQmhNQ1VFc3hEekFOQmdOVkJBc01CazVsWW5KaGN6RXFNQ2dHQTFVRUNnd2hUbVZpY21GeklFVnVkR1Z5Y0hKcGMyVWdVSEpwZG1GMFpTQk1hVzFwZEdWa01VVXdRd1lEVlFRREREeFBSMDR0TXpJMk5ETXhNVFExTFRNek16TXpNek16TWprNU1EQXdNRE5QUjA0dE16STJORE14TVRRMUxUTXpNek16TXpNek1qazVNREF3TURNd1ZqQVFCZ2NxaGtqT1BRSUJCZ1VyZ1FRQUNnTkNBQVFXK3FYeWVmdVNDTUpZL3FzcTBGZFVCYitpY3JWVXlQVThseTJjMVlEZElFY3BzTUpnRHdVVHlueGs3UU9DQlRIUStCNmt5QlprdXhmTUw3N21YaWlBbzRHME1JR3hNQXdHQTFVZEV3RUIvd1FDTUFBd2dhQUdBMVVkRVFTQm1EQ0JsYVNCa2pDQmp6RTdNRGtHQTFVRUJBd3lNUzFQUjA1OE1pMVBSMDU4TXkxbFpESXlaakZrT0MxbE5tVTBMVEV4TVRndE9XSTFPQzFrT1dFNFpqRXhaVFE0TjJneEh6QWRCZ29Ka2lhSmsvSXNaQUVCREE4ek5EUTBORFE1T1RrNU1EQXdPVE14RFRBTEJnTlZCQXdNQkRFeE1EQXhFVEFQQmdOVkJCb01DRkpTVWtVeE9USTVNUTB3Q3dZRFZRUVBEQVJVWldOb01Bb0dDQ3FHU000OUJBTUNBMGNBTUVRQ0lHbmVONk9sZlV4SWRaMU1pc0FMU1dHZjRlY1lEbWtyZ2FnV1ZUdGlwdnFpQWlCcUhNOUN0dkpEVmlzMUp6S29qVThHN2syR1h1MXJ6YWROY2s1eVpKMGoxZz09",
  // securityToken: "",
  // secretKey: "yUEy7sZ6DZ9BFPj3EQAXUw+JovtonpZRDgdiK5tx5Fo
  // session: "",
  // status: "",
  // version: "1",
  // dbtimestamp: "2022-08-

  const getSpecificCSID = async () => {
    try {
      const response = await axios.get(
        "https://api-zatca-uat.nebrascorp.com/api/csidcompliance/6620fd51fb3e68781bd48491"
      );
      console.log(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="CSR" breadcrumbItem="Generate CSID Onboarding" />
          <button onClick={() => getSpecificCSID()}>test csid fetching </button>
          <div style={containerStyle}>
            <div style={gridStyle} className={"ag-theme-quartz"}>
              <AgGridReact<any>
                rowData={complianceCSID}
                masterDetail={true}
                detailCellRenderer={detailCellRenderer}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
              />
            </div>
          </div>
        </Container>

        <Offcanvas
          isOpen={open}
          toggle={toggleLeftCanvas}
          direction="end"
          style={{ minWidth: "40%" }}
        >
          <OffcanvasHeader toggle={toggleLeftCanvas}>
            ADD CSR Details
          </OffcanvasHeader>
          <OffcanvasBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Form onSubmit={handleGenerateCSID} style={{ minWidth: "80%" }}>
              <FormGroup floating>
                <Input
                  type="select"
                  required
                  name="csid"
                  onChange={handleFormData}
                >
                  <option value="">Select csid </option>

                  {complianceCSID?.map((item: any, index: any) => {
                    return (
                      <option key={index} value={JSON.stringify(item)}>
                        {item._id}
                      </option>
                    );
                  })}
                </Input>
                <Label for="csr.organization.identifier">
                  Organization Identifier
                </Label>
              </FormGroup>{" "}
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      type="number"
                      name="otp"
                      onChange={handleFormData}
                      id="otp"
                      required
                    ></Input>
                    <Label for="">OTP</Label>
                  </FormGroup>{" "}
                </Col>

                <Col xs={6}>
                  <FormGroup floating>
                    <Input
                      type="text"
                      name="accept_version"
                      onChange={handleFormData}
                      id="accept_version"
                      required
                    ></Input>
                    <Label for="accept_version">Accept Version</Label>
                  </FormGroup>{" "}
                </Col>
              </Row>
              <Button className="btn btn-success">Generate CSID</Button>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default CSIDOnboardingGenerator;
